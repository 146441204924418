@import "core/variables";
@import "core/mixins";

// Core Components
@import "core/misc";

// Core Plugins

// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-slick";

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), url(../OpenSans.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


a:hover, a:focus {
  text-decoration: none;
  color: #aa182d;
}

.pswp__caption .pswp__caption__center {
  font-size: 22px;
  text-align: center;
  font-family: '"OpenSans", "Helvetica", "Arial", sans-serif';
  font-weight: 300;
  line-height: 1.5em
}

ul {
  margin: 0;
}
ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "-";
  text-indent: -5px;
}
